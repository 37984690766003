import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 275,
      paddingTop: 0,
      backgroundColor: 'black',
      color: 'white',
      '& .MuiCardContent-root': {
        paddingBottom: 16
      }
    },
    cardBullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(1.5)',
    },
    titleContainer: {
      display: 'flex', 
      alignItems: 'center',
      paddingBottom: 2
    },
    titlehr: {
      backgroundColor: 'rgb(168, 255, 96)',
      marginTop: 5,
      marginBottom: 15
    },
    companyAvatar: {
      border: '.04em solid white',
      marginRight: 15,
      width: theme.spacing(7),
      height: theme.spacing(7)
    },
    positionTitle: {
      paddingTop: 2,
      fontSize: 14,
    },
    positionDetailsContainer: {
      padding: '0px 30px'
    },
    positionSubtitle: {
      color: 'lightgray',
      paddingBottom: 8
    },
    positionDetails: {
      paddingLeft: '3em', 
      paddingRight: '3em', 
      textIndent: '-.5em',
      marginBottom: 5
    },
    breakIcon: {
      margin: '12px 0px 10px 80px',
      transform: 'rotate(90deg) scale(1.2)',
      color: 'rgb(168, 255, 96)'
    }
  })
);