import React from "react"
import Layout from "../components/Layout/layout"
import Typography from '@material-ui/core/Typography'
import ExperienceContainer from '../components/Experience/ExperienceContainer'
import SEO from "../components/seo"


const IndexPage = () => (
  <Layout>
    <br/>
    <SEO title="Home" />
    <Typography variant="h3" style={{paddingBottom: '15px'}}>Experience</Typography>
    <ExperienceContainer/>
  </Layout>
)

export default IndexPage
