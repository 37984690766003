import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    layoutFooter: {
      borderTop: "1px solid #A8FF60",
      backgroundColor: "#292929",
      textAlign: "center",
      position: "fixed",
      left: "0",
      bottom: "0",
      padding: "5px",
      height: "30px",
      width: "100%",
    },
    footerText: {
      fontWeight: 300,
      fontSize: 12
    },
    contactDivider: {
      fontSize: '14px',
      color: "#A8FF60"
    }
  }),
);