import React from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import { useStyles } from './experienceCardStyles'
import LinearScaleIcon from '@material-ui/icons/LinearScale'
import CompanyLogos from "../../static/data/company_logos.json"
import Avatar from '@material-ui/core/Avatar';

interface ExperienceCardProps {
  position: any
}

const ExperienceCard = (props: ExperienceCardProps) => {
    const { position } = props;

    const classes = useStyles();

    const getDurationString = (dateFromStr: string, dateToStr: string): string => {
      const dateTo = dateToStr ? new Date(dateToStr) : new Date();
      const dateFrom = new Date(dateFromStr)
      var months = dateTo.getMonth() - dateFrom.getMonth() + 1 + 12 * (dateTo.getFullYear() - dateFrom.getFullYear());
      const years = parseInt(months/12);
      months = months % 12;
      return (years <= 0 ? '' : ` ${years} year`) + (years > 1 ? 's' : '') + (months <= 0 ? '' : ` ${months} month`) + (months > 1 ? 's' : '');
    }

    const bull = <span className={classes.cardBullet}>•</span>;
    const hyphen = <span>-</span>

    const companyPositions = position.positions.map((subPosition, idx: number) => {

      const details = position.positions[idx].details.map((text: string, _: number) => (
        <Typography className={classes.positionDetails}>{bull}{text}</Typography>
      ));
      const divider = idx !== 0 && <LinearScaleIcon className={classes.breakIcon}/>;
      const duration = getDurationString(subPosition.date_start, subPosition.date_end)

      return (
        <div className={classes.positionDetailsContainer}>
          {divider}
          <Typography className={classes.positionSubtitle}>
              {subPosition.location} <br/>
              {subPosition.date_start} {hyphen} {subPosition.date_end ? subPosition.date_end : 'Present'} {bull} {duration}
          </Typography>
          <Typography variant="body2" component="p">{details}</Typography>
        </div>
      )
    });

    return (
      <>
      <Card className={classes.root}>
        <CardContent>
          <div className={classes.titleContainer}>
            <Avatar src={CompanyLogos.company_logos[position.company]} className={classes.companyAvatar}/>
            <Typography variant="h5" component="h2">
              {position.company}
            </Typography>
          </div>
          <hr className={classes.titlehr}/>
          {companyPositions}
        </CardContent>
      </Card>
      <br/>
      </>
    )
};

export default ExperienceCard