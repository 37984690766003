/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Typography from '@material-ui/core/Typography'
import { useState, useEffect } from "react";
import { useStyles } from './layoutStyles'
import Navbar from '../Navbar/Navbar'
import "./layout.css"


const Layout = ({ children }) => {
  const classes = useStyles();

  const [position, setPosition] = useState({ x: 0, y: 0 });
  useEffect(() => {
    const setFromEvent = e => setPosition({ x: e.clientX, y: e.clientY });
    window.addEventListener("mousemove", setFromEvent);
    return () => {
      window.removeEventListener("mousemove", setFromEvent);
    };
  }, []);

  const footerContent = (
    <>
      Scott Weitzner © {new Date().getFullYear()}
      <span className={classes.contactDivider}>&nbsp;|&nbsp;</span>
      <span className="emailUnselectable" data-unselectable="Scott.Weitzner@gmail.com"></span>
    </>
  );

  return (
    <>
      <Navbar x={position.x} y={position.y}/>
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `0 1.0875rem 1.45rem`,
        }}
      >
        <main>{children}</main>
        <div className={classes.layoutFooter}>
          <Typography className={classes.footerText}>
            {footerContent}
          </Typography>
        </div>
      </div>
      <br/>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
