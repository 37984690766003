import React from 'react';
import ExperienceCard from './experienceCard'
import LinkedInData from "../../static/data/linkedin_profile.json"


const ExperienceContainer = () => {

    const positionCards = LinkedInData.positions.map((position, _) => (
        <ExperienceCard position={position}/>
    ));

    return (
        <>{positionCards}</>
    )
};

export default ExperienceContainer